import { Button, Divider, Form, FormInstance, Input, Space } from 'antd';
import React, { ReactElement, useMemo } from 'react';
import moment from 'moment';
import { isArray } from 'lodash';
import Table, { ColumnsType } from 'antd/lib/table';
import { BillingCodeSelect } from '@/components/BillingCodeSelect';
import { filterModelFn } from '@/components/SyncCacheModelSelect';

type TSpecialTerms = {
  id?: number;
  code: string;
  name: string;
  special_term: string;
};

export const SpecialTerms: React.FC<{
  data: TSpecialTerms[];
  rightHeader?: ReactElement | false;
  updateData: (a: TSpecialTerms[]) => void;
  clickAdd?: () => void;
  arrayName?: string | string[];
  defaultSpecialTerms?: TSpecialTerms[];
  title?: string;
  category?: string;
  aiTerms?: TSpecialTerms[];
  onAcceptAiTerms?: (term: TSpecialTerms) => void;
}> = ({
  data = [],
  updateData,
  clickAdd,
  arrayName = 'special_terms',
  defaultSpecialTerms = [],
  title = 'Special Terms',
  category,
  aiTerms = [],
  onAcceptAiTerms,
}) => {
  const handleAddList = () => {
    if (clickAdd instanceof Function) {
      clickAdd();
    }

    updateData([
      ...data,
      {
        code: '',
        name: '',
        special_term: '',
      },
    ]);
  };

  const handleAcceptAiTerm = (term: TSpecialTerms) => {
    if (onAcceptAiTerms) {
      onAcceptAiTerms(term);
    }
  };

  const handlePropertyChange = (index: number, e: any) => {
    const {
      name,
      value,
    }: { name: string; value: string } = e.target as HTMLInputElement;
    const copy: any[] = [...data];
    copy[index][(name as unknown) as keyof TSpecialTerms] = value;
    updateData(copy);
  };

  const handleRemove = (index: number) => {
    const copy = [...data];
    copy.splice(index, 1);
    updateData(copy);
  };

  const toFormItemName = (name: any[]) => {
    return [...(isArray(arrayName) ? arrayName : [arrayName]), ...name];
  };

  const defaultColumns: ColumnsType<TSpecialTerms> = [
    {
      title: 'Code',
      key: 'code',
      width: 200,
      render: (text, record, dataIndex) => {
        return (
          <>
            <Form.Item shouldUpdate noStyle>
              {({ setFieldValue }) => (
                <Form.Item
                  className="mb0"
                  noStyle
                  name={toFormItemName([dataIndex, 'code'])}
                  rules={[{ required: true, message: '' }]}
                >
                  <BillingCodeSelect
                    size="small"
                    onChange={(e, option: any) => {
                      if (option) {
                        setFieldValue(
                          toFormItemName([dataIndex, 'name']),
                          option.name,
                        );
                      }
                    }}
                    category={category}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </>
        );
      },
    },
    {
      title: 'Name',
      key: 'name',
      width: 200,
      render: (text, record, dataIndex) => {
        return (
          <>
            <Form.Item
              className="mb0"
              noStyle
              name={toFormItemName([dataIndex, 'name'])}
              rules={[{ required: true, message: '' }]}
            >
              <Input size="small" />
            </Form.Item>
          </>
        );
      },
    },
    {
      title: 'Special Term',
      key: 'special_term',
      width: 200,
      render: (text, record, dataIndex) => {
        return (
          <>
            <Input
              size="small"
              name="special_term"
              value={record.special_term || ''}
              onChange={(e) => {
                handlePropertyChange(dataIndex, e);
              }}
            />
          </>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      width: 100,
      fixed: 'right',
      render: (text, record, dataIndex) => {
        return (
          <>
            <a onClick={() => handleRemove(dataIndex)}>Delete</a>
            {defaultSpecialTerms.length != 0 && (
              <>
                <Divider type="vertical" />
              </>
            )}
          </>
        );
      },
    },
  ];

  const columns = useMemo(() => {
    const _columns = [...defaultColumns];
    return _columns;
  }, [data]);

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <Space>
          <span>{title}</span>
          <Button onClick={handleAddList}>Add</Button>
        </Space>
      </div>

      {aiTerms.length > 0 && (
        <div style={{ marginBottom: 16 }}>
          <div style={{ fontSize: 12, color: '#666', marginBottom: 8 }}>
            AI Recognition:
          </div>
          <Table
            size="small"
            pagination={false}
            dataSource={aiTerms}
            columns={[
              {
                title: 'Code',
                dataIndex: 'code',
                key: 'code',
              },
              {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
              },
              {
                title: 'Special Term',
                dataIndex: 'special_term',
                key: 'special_term',
              },
              {
                title: 'Action',
                key: 'action',
                render: (_, record) => (
                  <Button
                    type="link"
                    size="small"
                    onClick={() => handleAcceptAiTerm(record)}
                  >
                    Accept
                  </Button>
                ),
              },
            ]}
          />
        </div>
      )}

      <Table
        size="small"
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ x: '100%' }}
      />
    </div>
  );
};

interface ISpcialTermsTab {
  form: FormInstance;
  handleChange: (event: any) => void;
}

export const SpecialTermsTab: React.FC<ISpcialTermsTab> = ({
  form,
  handleChange,
}) => {
  const handleClickAdd = () => {
    if (!form.getFieldValue('update_special_terms_at')) {
      form.setFieldsValue({
        update_special_terms_at: moment().format('YYYY-MM-DD'),
      });
    }
  };

  return (
    <>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => (
          <Form.Item
            name="special_terms"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <SpecialTerms
              rightHeader={<></>}
              clickAdd={handleClickAdd}
              data={getFieldValue('special_terms') || []}
              updateData={(list) => {
                handleChange({
                  target: { name: 'special_terms', value: list },
                });
              }}
            />
          </Form.Item>
        )}
      </Form.Item>
    </>
  );
};
