export const BIG_FIVE = [
  'MEDU',
  'MAEU',
  'CMDU',
  'COSU',
  'WHLC',
  'HLCU',
  'HDUJ',
  'ECLK',
  'SJHH',
  'TXZJ',
  'TSYN',
];

// MSC: MEDU

// MAERSK: MAEU

// CMA: CMDU

// COSCO: COSU

// WAN HAI LINE: WHLC

// ZIM: ZIMU

// OOCL: OOLU

// ONE: ONEY

// Evergreen: EGLV

// MATSON: MATS

// YANGMING: YMPR

// HAPAG LLOYD: HLCU

// HMM: HDMU

// SM Line: SMLM

export const SCAC_CODE_ORDER = [
  'MEDU',
  'MAEU',
  'CMDU',
  'COSU',
  'WHLC',
  'HLCU',
  'HDUJ',
  'ECLK',
  'SJHH',
  'TXZJ',
  'TSYN',
  'ZIMU',
  'OOLU',
  'ONEY',
  'EGLV',
  'MATS',
  'YMPR',
  'YMJA',
  'HDMU',
  'SMLM',
];

export const DIFF_BETWEEN_PORT_LFD_AND_LINE_LFD_SCAC_CODES = [
  'MEDU',
  'ZIMU',
  'COSU',
  'HLCU',
  'MAEU',
  'HDMU',
];
