import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Space,
  Table,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { BillingCodeSelect } from '@/components/BillingCodeSelect';
import { TerminalsSelect } from './TerminalsSelect';
import { useApp } from '@/utils/useapp';
import { capitalize, isArray } from 'lodash';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import CurrencyView from './CurrencyView';
import { filterModelFn } from './SyncCacheModelSelect';

const convert = (
  rate: string | number = 0,
  currency_rate: string | number = 1,
) => {
  return +(Math.round(+rate * +currency_rate * 100) / 100).toFixed(2);
};

type TPossibleCharge = {
  id?: number;
  code: string;
  name: string;
  rate: number | string;
  explanation: string;
  terminal_ids?: number[];
  unit: number;
  size: number;
  range: object;
  rules: any;
  currency: string;
  cntr_size: any[];
  display_rate: number;
};

export const CNTR = 0;
export const DAY = 1;
export const HOUR = 2;
export const TIME = 3;
export const WEIGHT = 4;
export const NIGHT = 5;

export const UNIT_MAP = {
  [CNTR]: 'CNTR',
  [DAY]: 'DAY',
  [HOUR]: 'HOUR',
  [TIME]: 'TIME',
  [WEIGHT]: 'WEIGHT',
  [NIGHT]: 'NIGHT',
};

export const ALL = 0;
export const TWENTY = 1;
export const FOURTY = 2;
export const FOURTY_FIVE = 3;
export const FIFTY_THREE = 4;

export const CNTR_SIZE_MAP = {
  [ALL]: 'All',
  [TWENTY]: '20',
  [FOURTY]: '40',
  [FOURTY_FIVE]: '45',
  [FIFTY_THREE]: '53',
};

const DISTANCE_RULE = 1;
const WEIGHT_RULE = 2;
const DRIVINGTIME_RULE = 3;

const RULE_MAP = {
  [DISTANCE_RULE]: 'Distance',
  [WEIGHT_RULE]: 'Weight',
  [DRIVINGTIME_RULE]: 'Driving time',
};

const PossibleChargeRules: React.FC<{
  arrayName: string | string[];
  index: number;
  rules: any[];
  handleDeleteOne: (i: number) => void;
  handleAddOne: () => void;
}> = ({ arrayName, index, handleDeleteOne, handleAddOne }) => {
  return (
    <Row gutter={1} align="middle">
      <Col span={2}>
        <PlusCircleOutlined onClick={() => handleAddOne()} />
      </Col>
      <Col span={22}>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldValue }) => {
            const rules = getFieldValue([arrayName, index, 'rules']);
            return (
              <>
                {(isArray(rules) ? rules : []).map((rule: any, i: number) => (
                  <Form.Item key={`rules_${i}`} className="mb-sm">
                    <Row align="middle">
                      <Col span={8}>
                        <Select
                          allowClear
                          showSearch
                          value={rule.type}
                          style={{ width: '100%' }}
                          onSelect={(v: number) => {
                            setFieldValue(
                              [arrayName, index, 'rules', i, 'type'],
                              v,
                            );
                          }}
                          placeholder="Please select"
                        >
                          {Object.entries(RULE_MAP).map(([key, value]) => (
                            <Select.Option key={`type_${key}`} value={+key}>
                              {value}
                            </Select.Option>
                          ))}
                        </Select>
                      </Col>
                      <Col span={12}>
                        {rule.type == DISTANCE_RULE && (
                          <>
                            <Row>
                              <Col span={12}>
                                <InputNumber
                                  value={rule.rule?.min || ''}
                                  onChange={(v) => {
                                    setFieldValue(
                                      [
                                        arrayName,
                                        index,
                                        'rules',
                                        i,
                                        'rule',
                                        'min',
                                      ],
                                      v,
                                    );
                                  }}
                                  addonAfter="Min"
                                />
                              </Col>
                              <Col span={12}>
                                <InputNumber
                                  value={rule.rule?.max || ''}
                                  onChange={(v) => {
                                    setFieldValue(
                                      [
                                        arrayName,
                                        index,
                                        'rules',
                                        i,
                                        'rule',
                                        'max',
                                      ],
                                      v,
                                    );
                                  }}
                                  addonAfter="Max"
                                />
                              </Col>
                            </Row>
                          </>
                        )}
                      </Col>
                      <Col span={4}>
                        <MinusCircleOutlined
                          className="ml-sm"
                          onClick={() => handleDeleteOne(i)}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                ))}
              </>
            );
          }}
        </Form.Item>
      </Col>
    </Row>
  );
};

export const RangeInput: React.FC<{
  unit: number;
  range: any;
  onChange: (T: any) => void;
}> = ({ unit, range = {}, onChange }) => {
  const handleChange: React.ChangeEventHandler = (e) => {
    const { name, value }: any = e.target;
    onChange({ ...range, [name]: value });
  };

  return (
    <>
      {(unit == DAY || unit == HOUR || unit == NIGHT) && (
        <Input.Group
          style={{
            width: '100%',
          }}
          size="small"
        >
          <Row gutter={8}>
            <Col span={11}>
              <Input
                type="number"
                size="small"
                value={range.min || ''}
                name="min"
                addonAfter="Min"
                onChange={handleChange}
              />
            </Col>
            <Col span={11}>
              <Input
                type="number"
                size="small"
                value={range.free || ''}
                name="free"
                addonAfter="Free"
                onChange={handleChange}
              />
            </Col>
          </Row>
        </Input.Group>
      )}
      {unit == TIME && (
        <Input.Group
          style={{
            width: '100%',
          }}
          size="small"
        >
          <Input
            className="site-input-right"
            type="number"
            style={{
              textAlign: 'center',
            }}
            name="max"
            value={range.max || ''}
            placeholder="Maximum"
            onChange={handleChange}
          />
        </Input.Group>
      )}
      {unit == WEIGHT && (
        <Input.Group
          style={{
            width: '100%',
          }}
          size="small"
          compact
        >
          <Input
            type="number"
            style={{ width: 100, textAlign: 'center' }}
            value={range.min || ''}
            name="min"
            placeholder="Minimum"
            onChange={handleChange}
          />
          <Input
            className="site-input-split"
            type="number"
            style={{
              width: 25,
              borderLeft: 0,
              borderRight: 0,
              pointerEvents: 'none',
            }}
            placeholder="~"
            disabled
          />
          <Input
            className="site-input-right"
            type="number"
            style={{
              width: 100,
              textAlign: 'center',
            }}
            name="max"
            value={range.max || ''}
            placeholder="Maximum"
            onChange={handleChange}
          />
        </Input.Group>
      )}
    </>
  );
};
const formatRange = (range: any) => {
  if (!range) {
    return '';
  }
  const values = Object.entries(range).map(([key, value]) => {
    return `${value} ${capitalize(key)}`;
  });
  return `${values.join(' ')}`;
};

export const PossibleCharges: React.FC<{
  data: TPossibleCharge[];
  rightHeader?: ReactElement | false;
  updateData: (a: TPossibleCharge[]) => void;
  clickAdd?: () => void;
  intermodal_region_id?: number;
  extra: ReactElement;
  arrayName?: string | string[];
  defaultPossibleCharge?: TPossibleCharge[];
  rules?: boolean;
  forceInit?: boolean;
  setForceInit?: (forceInit: boolean) => void;
  title?: string;
  currencyRate?: number;
  category?: string;
  aiPossibleCharges?: TPossibleCharge[];
  onAcceptAiPossibleCharges?: (charge: TPossibleCharge) => void;
}> = ({
  data = [],
  rightHeader = false,
  forceInit = false,
  setForceInit,
  updateData,
  clickAdd,
  intermodal_region_id,
  extra,
  arrayName = 'possible_charges',
  defaultPossibleCharge = [],
  rules = false,
  title = 'Possible Charges',
  currencyRate,
  category,
  aiPossibleCharges = [],
  onAcceptAiPossibleCharges,
}) => {
  const app = useApp();
  const [init, setInit] = useState(true);
  const [pcurrency, setPCurrency] = useState(
    data[0] && data[0].currency ? data[0].currency : 'USD',
  );
  const [pcurrencyRate, setPCurrencyRate] = useState<number>(1);

  const [terminalsSelectOptions, setTerminalsSelectOptions] = useState([]);

  useEffect(() => {
    if (currencyRate) {
      setPCurrencyRate(currencyRate);
      const possibleCharges = data[0] ? [...data] : [];
      for (let i = 0; i < possibleCharges.length; i++) {
        possibleCharges[i].display_rate =
          possibleCharges[i].currency == 'USD'
            ? +possibleCharges[i].rate
            : convert(possibleCharges[i].rate, currencyRate);
      }
      updateData(possibleCharges);
    }
  }, [currencyRate]);

  const initData = () => {
    if (!data[0] || !data[0].id) {
      return;
    }

    const possibleCharges = data[0] ? [...data] : [];
    for (let i = 0; i < possibleCharges.length; i++) {
      possibleCharges[i].display_rate =
        possibleCharges[i].currency == 'USD'
          ? +possibleCharges[i].rate
          : convert(possibleCharges[i].rate, currencyRate);
    }
    setPCurrency(data[0]?.currency || 'USD');
    updateData(possibleCharges);
  };

  useEffect(() => {
    if (init && data[0] && data[0].id) {
      // const possibleCharges = data[0] ? [...data] : [];
      // for (let i = 0; i < possibleCharges.length; i++) {
      //   possibleCharges[i].display_rate =
      //     possibleCharges[i].currency == 'USD'
      //       ? +possibleCharges[i].rate
      //       : convert(possibleCharges[i].rate, currencyRate);
      // }
      // setPCurrency(data[0]?.currency || 'USD');
      // updateData(possibleCharges);
      initData();
      setInit(false);
    }
  }, [data, pcurrencyRate]);

  useEffect(() => {
    if (forceInit) {
      initData();
      setForceInit && setForceInit(false);
    }
  }, [forceInit, data, setForceInit]);

  const handleAddList = () => {
    if (clickAdd instanceof Function) {
      clickAdd();
    }

    updateData([
      ...data,
      {
        code: '',
        name: '',
        rate: '',
        explanation: '',
        unit: 0,
        size: 0,
        range: {},
        rules: [],
        currency: pcurrency,
        cntr_size: [0],
        display_rate: 0,
      },
    ]);
  };

  const handlePropertyChange = (index: number, e: any) => {
    const {
      name,
      value,
    }: { name: string; value: string } = e.target as HTMLInputElement;
    const copy: any[] = [...data];
    copy[index][(name as unknown) as keyof TPossibleCharge] = value;
    updateData(copy);
  };

  const handleRemove = (index: number) => {
    const copy = [...data];
    copy.splice(index, 1);
    updateData(copy);
  };

  const handleReplaceFromdeFault = (record: TPossibleCharge) => {
    const alter = defaultPossibleCharge.find(
      (p: TPossibleCharge) =>
        record.code == p.code && record.unit == p.unit && record.size == p.size,
    );
    if (alter) {
      const row = { ...alter };
      delete row.id;
      const copy: any[] = [...data];
      copy[copy.indexOf(record)] = row;
      updateData(copy);
    }
  };

  const toFormItemName = (name: any[]) => {
    return [...(isArray(arrayName) ? arrayName : [arrayName]), ...name];
  };

  const defaultColumns: ColumnsType<TPossibleCharge> = [
    {
      title: 'Code',
      key: 'code',
      width: 200,
      render: (text, record, dataIndex) => {
        return (
          <>
            <Form.Item shouldUpdate noStyle>
              {({ setFieldValue }) => (
                <Form.Item
                  className="mb0"
                  noStyle
                  name={toFormItemName([dataIndex, 'code'])}
                  rules={[{ required: true, message: '' }]}
                >
                  <BillingCodeSelect
                    codeOnly
                    size="small"
                    onChange={(e, option: any) => {
                      if (option) {
                        setFieldValue(
                          toFormItemName([dataIndex, 'name']),
                          option.name,
                        );
                        setFieldValue(
                          toFormItemName([dataIndex, 'unit']),
                          option.unit || 0,
                        );
                      }
                    }}
                    category={category}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </>
        );
      },
    },
    {
      title: 'Name',
      key: 'name',
      width: 200,
      render: (text, record, dataIndex) => {
        return (
          <>
            <Form.Item
              className="mb0"
              noStyle
              name={toFormItemName([dataIndex, 'name'])}
              rules={[{ required: true, message: '' }]}
            >
              <Input size="small" />
            </Form.Item>
          </>
        );
      },
    },
    {
      title: 'Rate',
      key: 'rate',
      width: 200,
      render: (text, record, dataIndex) => {
        return (
          <>
            <Form.Item
              hidden
              name={toFormItemName([dataIndex, 'rate'])}
            ></Form.Item>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue, setFieldValue }) => (
                <Form.Item
                  className="mb0"
                  noStyle
                  name={toFormItemName([dataIndex, 'display_rate'])}
                >
                  <InputNumber
                    size="small"
                    onChange={(value) => {
                      setFieldValue(
                        toFormItemName([dataIndex, 'rate']),
                        getFieldValue(
                          toFormItemName([dataIndex, 'currency']),
                        ) === 'USD'
                          ? value
                          : value
                          ? Math.ceil((+value / pcurrencyRate) * 100) / 100
                          : 0,
                      );
                    }}
                    addonAfter={
                      getFieldValue(toFormItemName([dataIndex, 'currency'])) ||
                      'USD'
                    }
                  />
                </Form.Item>
              )}
            </Form.Item>
          </>
        );
      },
    },
    {
      title: 'Unit',
      key: 'unit',
      width: 150,
      render: (text, record, dataIndex) => {
        return (
          <Form.Item
            className="mb0"
            noStyle
            name={toFormItemName([dataIndex, 'unit'])}
            rules={[{ required: true, message: '' }]}
            initialValue={0}
          >
            <Select size="small">
              {Object.keys(UNIT_MAP).map((key) => (
                <Select.Option key={+key} value={+key}>
                  {UNIT_MAP[(key as unknown) as keyof typeof UNIT_MAP]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        );
      },
    },
    {
      title: 'CNTR Size',
      key: 'cntr_size',
      width: 200,
      render: (text, record, dataIndex) => {
        return (
          <Form.Item
            className="mb0"
            noStyle
            initialValue={[0]}
            name={toFormItemName([dataIndex, 'cntr_size'])}
          >
            <Select size="small" mode="multiple">
              {Object.keys(CNTR_SIZE_MAP).map((key) => (
                <Select.Option key={+key} value={+key}>
                  {
                    CNTR_SIZE_MAP[
                      (key as unknown) as keyof typeof CNTR_SIZE_MAP
                    ]
                  }
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        );
      },
    },
    {
      title: 'Range',
      key: 'range',
      width: 280,
      render: (text, record, dataIndex) => {
        return (
          <Form.Item shouldUpdate noStyle>
            {({ setFieldValue }) => (
              <Form.Item
                className="mb0"
                noStyle
                initialValue={{}}
                name={toFormItemName([dataIndex, 'range'])}
              >
                <RangeInput
                  unit={record?.unit || 0}
                  range={record?.range || {}}
                  onChange={(value) => {
                    setFieldValue('range', value);
                  }}
                />
              </Form.Item>
            )}
          </Form.Item>
        );
      },
    },
    {
      title: 'Probability',
      key: 'probability',
      width: 200,
      render: (text, record, dataIndex) => {
        return (
          <>
            <Form.Item
              className="mb0"
              noStyle
              name={toFormItemName([dataIndex, 'probability'])}
            >
              <InputNumber size="small" max={100} step={1} />
            </Form.Item>
          </>
        );
      },
    },
    {
      title: 'Explanation',
      key: 'explanation',
      width: 200,
      render: (text, record, dataIndex) => {
        return (
          <>
            <Input
              size="small"
              name="explanation"
              value={record.explanation || ''}
              onChange={(e) => {
                handlePropertyChange(dataIndex, e);
              }}
            />
          </>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      width: 100,
      fixed: 'right',
      render: (text, record, dataIndex) => {
        return (
          <>
            <a onClick={() => handleRemove(dataIndex)}>Delete</a>
            {defaultPossibleCharge.length != 0 && (
              <>
                <Divider type="vertical" />
                <a onClick={() => handleReplaceFromdeFault(record)}>Default</a>
              </>
            )}
          </>
        );
      },
    },
  ];

  if (rules) {
    defaultColumns.splice(defaultColumns.length - 2, 0, {
      title: 'Rules',
      key: 'rules',
      width: 500,
      render: (text, record, dataIndex) => {
        return (
          <Form.Item shouldUpdate noStyle>
            {({ setFieldValue, getFieldValue }) => (
              <Form.Item
                className="mb0"
                noStyle
                initialValue={{}}
                name={toFormItemName([dataIndex, 'rules'])}
              >
                <PossibleChargeRules
                  arrayName={arrayName}
                  index={dataIndex}
                  rules={record.rules || []}
                  handleAddOne={() => {
                    const rules = getFieldValue([
                      arrayName,
                      dataIndex,
                      'rules',
                    ]);
                    setFieldValue(toFormItemName([dataIndex, 'rules']), [
                      ...rules,
                      {
                        id: '',
                        type: DISTANCE_RULE,
                        possible_charge_id: record.id,
                      },
                    ]);
                  }}
                  handleDeleteOne={(idx) => {
                    const rules = getFieldValue([
                      arrayName,
                      dataIndex,
                      'rules',
                    ]);
                    rules.splice(idx, 1);
                    setFieldValue(toFormItemName([dataIndex, 'rules']), [
                      ...rules,
                    ]);
                  }}
                />
              </Form.Item>
            )}
          </Form.Item>
        );
      },
    });
  }

  const alterColumns: ColumnsType<TPossibleCharge> = [
    {
      title: 'Code',
      key: 'code',
      width: 200,
      render: (text, record) => {
        return record?.code || '';
      },
      sorter: (a, b) => a.code.localeCompare(b.code),
    },
    {
      title: 'Name',
      key: 'name',
      width: 200,
      render: (text, record) => {
        return record?.name || '';
      },
    },
    {
      title: 'Rate',
      key: 'rate',
      width: 200,
      render: (text, record) => {
        return '$' + (record?.rate || '');
      },
    },
    {
      title: 'Unit',
      key: 'unit',
      width: 150,
      render: (text, record) => {
        return UNIT_MAP[(record.unit as unknown) as keyof typeof UNIT_MAP];
      },
    },
    {
      title: 'CNTR Size',
      key: 'cntr_size',
      width: 200,
      render: (text, record) => {
        return record.cntr_size.length == 0
          ? 'All'
          : record.cntr_size
              .map((key: any) => {
                CNTR_SIZE_MAP[(key as unknown) as keyof typeof CNTR_SIZE_MAP];
              })
              .join(' ');
      },
    },
    {
      title: 'Range',
      key: 'range',
      width: 280,
      render: (text, record) => {
        return formatRange(record.range);
      },
    },
    {
      title: 'Explanation',
      key: 'explanation',
      width: 200,
      render: (text, record) => {
        return record?.explanation || '';
      },
    },
  ];

  const fetchTerminals = async (ir: number) => {
    try {
      const resp = await app.service.get('terminals', {
        params: {
          intermodal_region_id: ir,
          active: 1,
        },
      });
      setTerminalsSelectOptions(resp.data);
    } catch (err: any) {
      message.error(err.data.message);
    }
  };

  useEffect(() => {
    if (!intermodal_region_id) {
      return;
    }
    fetchTerminals(intermodal_region_id);
  }, [intermodal_region_id]);

  const columns = useMemo(() => {
    const _columns = [...defaultColumns];
    if (intermodal_region_id) {
      _columns.splice(_columns.length - 1, 0, {
        title: 'Terminals',
        key: 'terminals',
        width: 200,
        render: (text, record, dataIndex) => {
          return (
            <Form.Item noStyle shouldUpdate>
              {() => (
                <>
                  <Form.Item
                    className="mb0"
                    name={toFormItemName([dataIndex, 'terminal_ids'])}
                  >
                    <TerminalsSelect
                      btnSize="small"
                      defaultSelected={record.terminal_ids}
                      onChange={(selected) =>
                        handlePropertyChange(dataIndex, {
                          target: {
                            name: 'terminal_ids',
                            value: selected,
                          },
                        })
                      }
                      options={terminalsSelectOptions}
                      intermodal_region_id={intermodal_region_id}
                    />
                  </Form.Item>
                </>
              )}
            </Form.Item>
          );
        },
      });
    }
    return _columns;
  }, [data, intermodal_region_id, terminalsSelectOptions]);

  const onCurrencyChange = async (value: string) => {
    setPCurrency(value);
    const possibleCharges = [...data];
    for (let i = 0; i < possibleCharges.length; i++) {
      possibleCharges[i].rate =
        value == 'USD'
          ? possibleCharges[i].display_rate
          : possibleCharges[i].display_rate / pcurrencyRate;

      possibleCharges[i].currency = value;
    }
    updateData(possibleCharges);
  };

  return (
    <div>
      <Space style={{ justifyContent: 'space-between', width: '100%' }}>
        <h3>
          {title}
          <Space>
            <Button onClick={handleAddList} style={{ marginLeft: '5px' }}>
              Add
            </Button>
            {currencyRate && (
              <Select
                value={pcurrency}
                onSelect={onCurrencyChange}
                options={[
                  { label: 'USD', value: 'USD' },
                  { label: 'CAD', value: 'CAD' },
                ]}
              ></Select>
            )}
            {pcurrency !== 'USD' && <CurrencyView data={data} />}
            {extra ? extra : ''}
          </Space>
        </h3>

        <div>{rightHeader}</div>
      </Space>

      {aiPossibleCharges.length > 0 && (
        <div style={{ marginBottom: 16 }}>
          <div style={{ fontSize: 12, color: '#666', marginBottom: 8 }}>
            AI Recognition:
          </div>
          <Table
            size="small"
            pagination={false}
            dataSource={aiPossibleCharges}
            columns={[
              {
                title: 'Code',
                dataIndex: 'code',
                key: 'code',
              },
              {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
              },
              {
                title: 'Rate',
                dataIndex: 'rate',
                key: 'rate',
                render: (text) => `$${text || ''}`,
              },
              {
                title: 'Unit',
                dataIndex: 'unit',
                key: 'unit',
                render: (unit) => UNIT_MAP[unit as keyof typeof UNIT_MAP] || '',
              },
              {
                title: 'Action',
                key: 'action',
                render: (_, record) => (
                  <Button
                    type="link"
                    size="small"
                    onClick={() => {
                      if (onAcceptAiPossibleCharges) {
                        onAcceptAiPossibleCharges(record);
                      }
                    }}
                  >
                    Accept
                  </Button>
                ),
              },
            ]}
          />
        </div>
      )}

      <Table
        size="small"
        columns={columns}
        // dataSource={data}
        // sort data by code asc
        // if a.id is null, it will be placed at the start of the list
        dataSource={data.sort((a, b) =>
          a.id ? (b.id ? a.code.localeCompare(b.code) : -1) : 1,
        )}
        pagination={false}
        scroll={{ x: '100%' }}
      />

      {defaultPossibleCharge.length > 0 && (
        <>
          <h3>IR Possible Charges</h3>
          <Table
            size="small"
            columns={alterColumns}
            dataSource={defaultPossibleCharge}
            pagination={false}
            scroll={{ x: '100%' }}
          />
        </>
      )}
    </div>
  );
};
