import {
  LoadingOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  RedoOutlined,
} from '@ant-design/icons';
import { Tag, Tooltip, Space } from 'antd';
import type { FileStatus } from '../interface/types';

export const FileStatusIcon = ({
  status,
  onRetry,
}: {
  status: FileStatus['status'];
  onRetry?: () => void;
}) => {
  switch (status) {
    case 'processing':
      return <LoadingOutlined style={{ color: '#1890ff' }} />;
    case 'completed':
      return <CheckCircleOutlined style={{ color: '#52c41a' }} />;
    case 'error':
      return (
        <Space>
          <CloseCircleOutlined style={{ color: '#ff4d4f' }} />
          {onRetry && (
            <RedoOutlined
              style={{ color: '#1890ff', cursor: 'pointer' }}
              onClick={onRetry}
            />
          )}
        </Space>
      );
    default:
      return null;
  }
};

export const FileStatusText = ({
  status,
  documentType,
  errorMessage,
  onRetry,
}: {
  status: FileStatus['status'];
  documentType?: string;
  errorMessage?: string;
  onRetry?: () => void;
}) => {
  if (status === 'processing') {
    return (
      <Tag color="processing">
        {documentType === 'invoice'
          ? 'Recognizing Content...'
          : 'Identifying File Type...'}
      </Tag>
    );
  }

  if (status === 'completed') {
    return <Tag color="success">Completed</Tag>;
  }

  if (status === 'error') {
    return (
      <Space>
        <Tooltip title={errorMessage}>
          <Tag color="error" style={{ cursor: 'help' }}>
            Failed
          </Tag>
        </Tooltip>
        {onRetry && (
          <RedoOutlined
            style={{ color: '#1890ff', cursor: 'pointer' }}
            onClick={onRetry}
            title="Retry"
          />
        )}
      </Space>
    );
  }

  return null;
};
