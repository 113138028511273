import styles from 'res/css/ui.scss';
import { useApp } from '@/utils/useapp';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Tag,
  message,
} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { VendorSelect } from '@/components/VendorSelect';
import { WarehouseSelect } from '@/components/WarehouseSelect';
import { groupBy, mapValues, uniq } from 'lodash';
import update from 'immutability-helper';
import {
  DISPATCH_AP_STATUS_OPTIONS,
  DISPATCH_AR_STATUS_OPTIONS,
  DISPATCH_STATUS_OPTIONS,
  DISPATCH_ACTION_V2_OPTIONS,
  LIVE_OR_DROP_MAP,
  OCEAN_PORT,
  RAMP_PORT,
} from '../data';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import { IntermodalRegionSelect } from '@/components/IntermodalRegionSelect';
import { useForm } from 'antd/lib/form/Form';
import Slider from '@/components/Slider';
import { CompanySyncSelect } from '@/components/CompanySyncSelect';
import { OceanCarrierSelect } from '@/components/OceanCarrierSelect';
import { TerminalSelect } from '@/components/TerminalSelect';
import { CitySelect } from '@/components/CitySelect';
import { CACHE_CONTAINER_COLOR_TAG_KEY } from '@/stores/cache';
import { ManagerFilterItems } from './ManagerFilterItems';
import { CommoditiesSyncSelect } from '@/components/CommoditiesSyncSelect';
import { OptionProps } from 'antd/lib/select';
import dataKeys from './data';
import { OPDASHBOARD_TITLES } from '@/pages/setting/admin/components/data';

const OPTION_ATTRIBUTES = [dataKeys.TITLE];

const SINGLE_UPDATE_ATTRIBUTE = [
  'pierpass',
  'eta',
  'dispatch_action_v2',
  // 'dispatch_overdue',
  'task_overdue',
  'op_task_overdue',
  'cps_task_overdue',
  'oa_task_overdue',
  'act_overdue',
  'urgent',
  'dg',
  'soc',
  'overweight',
  'reefer',
  'bonded',
  'diversion',
  'multi_deliveries',
  'transload',
  'weekend_or_holiday',
  'x_ray',
  'met',
  'cet',
  'vendor_verified',
  'bill_unapproved',
  dataKeys.WHS_SPECIAL,
  'confirmed_at',
  'is_verified',
  'vendor_is_oog',
  dataKeys.TITLE,
];

type TFilter = {
  // checkedFilterItems: string[];
  values: any[];
  allowAttributes?: string[];
  accountingMode?: boolean;
  removeAll: () => void;
  removeOne: (item: any) => void;
  onChange: (t: any) => void;
  // onChangeFilterItems: (checkedItems: string[]) => void;
  activeFilters: any[];
  show: boolean;
  loading: boolean;
  hideFilter: () => void;
};

export const convertFilterValuesToQueryParams = (values: any[]) => {
  if (values?.find((v) => v.meta)) {
    values = [
      ...values,
      ...(Object.entries(values?.find((v) => v.meta)?.meta)?.map((v) => ({
        attribute: v[0],
        value: v[1],
      })) || []),
    ];
  }
  return mapValues(groupBy(values, 'attribute'), (items) => {
    return items.length > 1 ? items.map((i) => i.value) : items[0].value;
  });
};

export const toFilterValue = (
  text: string,
  attribute: string,
  value: string[] | number[] | boolean | string,
) => {
  return {
    text,
    attribute,
    value,
  };
};

const YES_OPTION = {
  label: 'Yes',
  value: true,
};

const NO_OPTION = {
  label: 'No',
  value: false,
};

const BINARY_OPTIONS = [YES_OPTION, NO_OPTION];

export const Filter: React.FC<TFilter> = ({
  values,
  accountingMode = false,
  allowAttributes = [],
  onChange,
  removeAll,
  removeOne,
  activeFilters,
  show,
  hideFilter,
  loading,
}) => {
  const app = useApp();

  const [checkedFilterItems, setCheckedFilterItem] = useState<Array<string>>(
    [],
  );
  const [newValues, setNewValues] = useState<Array<any>>([]);
  const [settingContainerTasks, setSettingContainerTasks] = useState<
    Array<any>
  >([]);

  const [localActiveFilters, setLocalActiveFilters] = useState<Array<any>>([]);

  useEffect(() => {
    if (show) {
      setLocalActiveFilters(activeFilters);
    }
  }, [show, activeFilters]);

  const [filter] = useForm();

  useEffect(() => {
    const _meta: any = activeFilters?.find((n) => n.meta);
    if (show) {
      // const _meta: any = activeFilters.find((n) => n.meta);
      if (_meta?.meta) {
        filter.setFieldsValue(_meta?.meta);
      } else {
        filter.resetFields(RESET_FIELDS);
      }

      if (!settingContainerTasks) {
        fetchSettingContainerTasks();
      }
    } else {
      filter.setFieldsValue(_meta?.meta);
    }
  }, [show, activeFilters]);

  const removeLocalFilters = (index: number) => {
    const copy = [...localActiveFilters];

    copy.splice(index, 1);

    setLocalActiveFilters([...copy]);
  };

  const fetchSettingContainerTasks = async () => {
    try {
      const resp = await app.service.get('dispatch/settingContainerTasks/');
      setSettingContainerTasks(resp.data);
    } catch (err: any) {
      message.error(err.data.message);
    }
  };

  useEffect(() => {
    fetchSettingContainerTasks();
  }, []);

  const handleEtaChange = ({
    etaStart,
    etaEnd,
  }: {
    etaStart: string;
    etaEnd: string;
  }) => {
    const index = newValues.findIndex((v) => v.attribute === 'eta');
    const value = toFilterValue(`ETA ${etaStart} - ${etaEnd}`, 'eta', [
      etaStart,
      etaEnd,
    ]);

    if (index !== -1) {
      setNewValues(update(newValues, { [index]: { $set: value } }));
    } else {
      setNewValues([...newValues, value]);
    }
  };

  const handleOrderDateChange = ({
    start,
    end,
  }: {
    start: string;
    end: string;
  }) => {
    const index = newValues.findIndex((v) => v.attribute === 'confirmed_at');
    const value = toFilterValue(
      `Order Date ${start} - ${end}`,
      'confirmed_at',
      [start, end],
    );

    if (index !== -1) {
      setNewValues(update(newValues, { [index]: { $set: value } }));
    } else {
      setNewValues([...newValues, value]);
    }
  };

  const handleMultipleSelectChange = (
    values: any,
    options: any,
    attribute: string,
    textPrefix?: string,
  ) => {
    if (options) {
      const newFilterValues = newValues.filter(
        (v) => v.attribute !== attribute || values.includes(v.value),
      );

      options.map((o: any) => {
        const index = newFilterValues.findIndex(
          (v) => v.attribute === attribute && v.value == o.value,
        );

        const text = textPrefix ? `${textPrefix}: ${o.label}` : `${o.label}`;

        const value = toFilterValue(text, attribute, o.value);

        if (index !== -1) {
          update(newFilterValues, { [index]: { $set: value } });
        } else {
          newFilterValues.push(value);
        }
      });
      setNewValues(newFilterValues);
    }
  };

  const handleVendorChange = (vendor: any) => {
    if (vendor) {
      const vendorIds = vendor.map((v: any) => v.id);
      handleMultipleSelectChange(
        vendorIds,
        vendor.map((v: any) => ({ value: v.id, label: v.name })),
        dataKeys.TRUCKER,
        'Vendor',
      );
    }
  };

  const handleOceanCarriersChange = (ids: any, option: any) => {
    if (ids.length > 0) {
      handleMultipleSelectChange(
        ids,
        option,
        dataKeys.OCEAN_CARRIER,
        'Ocean Carrier',
      );
    }
  };

  const handleTerminalsChange = (terminal: any) => {
    if (terminal) {
      handleMultipleSelectChange(
        [terminal.id],
        [{ value: terminal.id, label: terminal.name }],
        dataKeys.TERMINAL,
        'Terminal',
      );
    }
  };

  const handleCityChange = (city: any) => {
    if (city) {
      handleMultipleSelectChange(
        [city.id],
        [{ value: city.id, label: city.full_name }],
        dataKeys.WAREHOUSE_CITY,
        'City',
      );
    }
  };

  const handleStatusChange = (options: Array<any>, key: string) => {
    if (options.length > 0) {
      handleMultipleSelectChange(
        options.map((o) => o.id),
        options,
        key,
        key.split('_').join(' ').toUpperCase(),
      );
    }
  };

  const handleContainerTypeChange = (options: Array<any>) => {
    if (options.length > 0) {
      handleMultipleSelectChange(
        options.map((o) => o.value),
        options.map((o) => ({ value: o.value, label: o.key })),
        'type',
        '',
      );
    }
  };

  const handleTagsChange = (options: Array<any>) => {
    if (options.length > 0) {
      handleMultipleSelectChange(
        options.map((o) => o.value),
        options.map((o) => ({ value: o.key, label: o.value })),
        'tags',
        'Tag',
      );
    }
  };

  const handleContainerTasksChange = (options: Array<any>) => {
    if (options.length > 0) {
      handleMultipleSelectChange(
        options.map((o) => o.value),
        options.map((o) => ({ value: o.value, label: o.children })),
        dataKeys.CONTAINER_TASK_DONE,
        'ContainerTask',
      );
    }
  };

  const handleInputChange = (
    value: any,
    attribute: string,
    textPrefix: string,
  ) => {
    handleChange(
      {
        target: {
          value,
          attribute,
        },
      },
      {
        textPrefix,
      },
    );
  };

  const handleInputEnter = (e: any, attribute: string, textPrefix: string) => {
    if (e.key == 'Enter') {
      handleInputChange(e.target.value, attribute, textPrefix);
      handleApply();
    }
  };

  const handleBinaryChange = (v: any, key: string, title: string) => {
    const index = newValues.findIndex((v) => v.attribute === key);
    const value = toFilterValue(
      (title || key) + (v === true ? ' Yes' : ' No'),
      key,
      v === true,
    );

    if (index !== -1) {
      setNewValues(update(newValues, { [index]: { $set: value } }));
    } else {
      setNewValues([...newValues, value]);
    }
  };

  const handleLiveOrDropChange = (v: any) => {
    const index = newValues.findIndex((v) => v.attribute === 'live_or_drop');
    const value = toFilterValue(
      LIVE_OR_DROP_MAP[(v as unknown) as keyof typeof LIVE_OR_DROP_MAP],
      'live_or_drop',
      v,
    );

    if (index !== -1) {
      setNewValues(update(newValues, { [index]: { $set: value } }));
    } else {
      setNewValues([...newValues, value]);
    }
  };

  const defaultRows = [
    {
      title: 'Order ID',
      item: (
        <Form.Item className="mb0" name="order_id">
          <Input
            size="small"
            allowClear
            placeholder="Enter Order ID"
            onChange={(e) => {
              handleChange(
                {
                  target: {
                    value: e.target.value,
                    attribute: 'order_id',
                  },
                },
                {
                  textPrefix: 'Order ID',
                },
              );
            }}
          />
        </Form.Item>
      ),
      enableIsNot: true,
      key: 'order_id',
    },
    {
      title: 'MBL',
      item: (
        <Form.Item className="mb0" name="mbl_number">
          <Input
            size="small"
            allowClear
            placeholder="Enter MBL#"
            onChange={(e) => {
              handleChange(
                {
                  target: {
                    value: e.target.value,
                    attribute: 'mbl_number',
                  },
                },
                {
                  textPrefix: 'MBL#',
                },
              );
            }}
          />
        </Form.Item>
      ),
      enableIsNot: true,
      key: 'mbl_number',
    },
    {
      title: 'CNTR',
      item: (
        <Form.Item className="mb0" name="number">
          <Input
            allowClear
            size="small"
            placeholder="Enter CNT#"
            onChange={(e) => {
              handleChange(
                {
                  target: {
                    value: e.target.value,
                    attribute: 'number',
                  },
                },
                {
                  textPrefix: 'CNTR#',
                },
              );
            }}
          />
        </Form.Item>
      ),
      enableIsNot: true,
      key: 'number',
    },
    {
      title: 'Commodity',
      item: (
        <Form.Item className="mb0" name="commodity">
          <CommoditiesSyncSelect
            size="small"
            onSelect={(_: number, option: OptionProps) => {
              handleChange(
                {
                  target: {
                    value: option.label,
                    attribute: 'commodity',
                  },
                },
                {
                  textPrefix: 'Commodity',
                },
              );
            }}
          />
        </Form.Item>
      ),
      // enableIsNot: true,
      key: 'commodity',
    },
    {
      title: 'Dispatch Status V2',
      item: (
        <Form.Item className="mb0" name="dispatch_status_v2">
          <Select
            size="small"
            allowClear
            mode="multiple"
            value={undefined}
            style={{ width: '100%' }}
            filterOption={(input, option) =>
              ((option!.label as unknown) as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            maxTagCount="responsive"
            onChange={(value: any, option: any) =>
              handleMultipleSelectChange(value, option, 'dispatch_status_v2')
            }
            options={DISPATCH_ACTION_V2_OPTIONS}
          />
        </Form.Item>
      ),
      enableIsNot: true,
      key: 'dispatch_status_v2',
    },
    {
      title: 'Customer',
      item: (
        <Form.Item name={dataKeys.CUSTOMER} className="mb0">
          <UserSyncSelect
            size="small"
            allowClear
            mode="multiple"
            style={{ width: '100%' }}
            maxTagCount="responsive"
            onChange={(value: any, option: any) =>
              handleMultipleSelectChange(
                value,
                option,
                dataKeys.CUSTOMER,
                'Customer',
              )
            }
          />
        </Form.Item>
      ),
      enableIsNot: true,
      key: dataKeys.CUSTOMER,
    },
    {
      title: 'Customer Company',
      item: (
        <Form.Item className="mb0" name={dataKeys.CUSTOMER_COMPANY}>
          <CompanySyncSelect
            size="small"
            allowClear
            mode="multiple"
            style={{ width: '100%' }}
            maxTagCount="responsive"
            onChange={(value: any, option: any) =>
              handleMultipleSelectChange(
                value,
                option,
                dataKeys.CUSTOMER_COMPANY,
                'Customer Company',
              )
            }
          />
        </Form.Item>
      ),
      enableIsNot: true,
      key: dataKeys.CUSTOMER_COMPANY,
    },
    {
      title: 'Op Leader',
      item: (
        <Form.Item className="mb0" name="operator_lead_id">
          <UserSyncSelect
            size="small"
            allowClear
            mode="multiple"
            type="admin"
            style={{ width: '100%' }}
            maxTagCount="responsive"
            onChange={(value: any, option: any) =>
              handleMultipleSelectChange(
                value,
                option,
                'operator_lead_id',
                'Op Leader',
              )
            }
          />
        </Form.Item>
      ),
      enableIsNot: true,
      key: 'operator_lead_id',
    },
    {
      title: 'Operator',
      item: (
        <Form.Item className="mb0" name={dataKeys.OPERATOR}>
          <UserSyncSelect
            size="small"
            value={undefined}
            mode="multiple"
            style={{ width: '100%' }}
            maxTagCount="responsive"
            type="admin"
            allowClear
            onChange={(value: any, option: any) =>
              handleMultipleSelectChange(value, option, dataKeys.OPERATOR, 'OP')
            }
          />
        </Form.Item>
      ),
      enableIsNot: true,
      key: dataKeys.OPERATOR,
    },
    {
      title: 'CPS',
      item: (
        <Form.Item className="mb0" name={dataKeys.CARRIER_PARTNER_SUCCESS}>
          <UserSyncSelect
            size="small"
            value={undefined}
            mode="multiple"
            style={{ width: '100%' }}
            maxTagCount="responsive"
            type="admin"
            allowClear
            onChange={(value: any, option: any) =>
              handleMultipleSelectChange(
                value,
                option,
                dataKeys.CARRIER_PARTNER_SUCCESS,
                'CPS',
              )
            }
          />
        </Form.Item>
      ),
      enableIsNot: true,
      key: dataKeys.CARRIER_PARTNER_SUCCESS,
    },
    {
      title: 'Title',
      item: (
        <Form.Item className="mb0" name={dataKeys.TITLE}>
          <Select
            size="small"
            onChange={(val, option: any) => {
              handleInputChange(val, dataKeys.TITLE, 'Title');
            }}
            allowClear
          >
            {OPDASHBOARD_TITLES.map((title: string) => (
              <Select.Option key={title}>{title}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      ),
      enableIsNot: true,
      key: dataKeys.TITLE,
    },
    {
      title: 'Op Assistant',
      item: (
        <Form.Item className="mb0" name={dataKeys.OP_ASSISTANT}>
          <UserSyncSelect
            size="small"
            allowClear
            mode="multiple"
            type="admin"
            style={{ width: '100%' }}
            maxTagCount="responsive"
            onChange={(value: any, option: any) =>
              handleMultipleSelectChange(
                value,
                option,
                dataKeys.OP_ASSISTANT,
                'Op Assistant',
              )
            }
          />
        </Form.Item>
      ),
      enableIsNot: true,
      key: dataKeys.OP_ASSISTANT,
    },
    {
      title: 'Agent',
      item: (
        <Form.Item className="mb0" name={dataKeys.AGENT}>
          <UserSyncSelect
            size="small"
            allowClear
            mode="multiple"
            type="user"
            style={{ width: '100%' }}
            maxTagCount="responsive"
            onChange={(value: any, option: any) =>
              handleMultipleSelectChange(value, option, dataKeys.AGENT, 'Agent')
            }
          />
        </Form.Item>
      ),
      enableIsNot: true,
      key: dataKeys.AGENT,
    },
    {
      title: 'BD',
      item: (
        <Form.Item className="mb0" name={dataKeys.BUSINESS_DEVELOPMENT}>
          <UserSyncSelect
            size="small"
            value={undefined}
            mode="multiple"
            style={{ width: '100%' }}
            maxTagCount="responsive"
            type="admin"
            allowClear
            onChange={(value: any, option: any) =>
              handleMultipleSelectChange(
                value,
                option,
                dataKeys.BUSINESS_DEVELOPMENT,
                'BD',
              )
            }
          />
        </Form.Item>
      ),
      enableIsNot: true,
      key: dataKeys.BUSINESS_DEVELOPMENT,
    },
    {
      title: 'Sales',
      item: (
        <Form.Item className="mb0" name={dataKeys.SALES}>
          <UserSyncSelect
            size="small"
            value={undefined}
            mode="multiple"
            style={{ width: '100%' }}
            maxTagCount="responsive"
            type="admin"
            allowClear
            onChange={(value: any, option: any) =>
              handleMultipleSelectChange(value, option, dataKeys.SALES, 'Sales')
            }
          />
        </Form.Item>
      ),
      enableIsNot: true,
      key: dataKeys.SALES,
    },
    {
      title: 'Sales Support',
      item: (
        <Form.Item className="mb0" name={dataKeys.SALES_SUPPORT}>
          <UserSyncSelect
            size="small"
            value={undefined}
            mode="multiple"
            style={{ width: '100%' }}
            maxTagCount="responsive"
            type="admin"
            allowClear
            onChange={(value: any, option: any) =>
              handleMultipleSelectChange(
                value,
                option,
                dataKeys.SALES_SUPPORT,
                'Sales Support',
              )
            }
          />
        </Form.Item>
      ),
      enableIsNot: true,
      key: dataKeys.SALES_SUPPORT,
    },
    {
      title: 'POD',
      item: (
        <Form.Item className="mb0" name={dataKeys.POD}>
          <IntermodalRegionSelect
            size="small"
            value={undefined}
            style={{ width: '100%' }}
            mode="multiple"
            maxTagCount="responsive"
            onChange={(value: any, option: any) =>
              handleMultipleSelectChange(value, option, dataKeys.POD, 'POD')
            }
          />
        </Form.Item>
      ),
      enableIsNot: true,
      key: dataKeys.POD,
    },
    {
      title: 'IR',
      item: (
        <Form.Item className="mb0" name={dataKeys.IR}>
          <IntermodalRegionSelect
            size="small"
            value={undefined}
            style={{ width: '100%' }}
            mode="multiple"
            maxTagCount="responsive"
            onChange={(value: any, option: any) =>
              handleMultipleSelectChange(value, option, dataKeys.IR, 'IR')
            }
          />
        </Form.Item>
      ),
      enableIsNot: true,
      key: dataKeys.IR,
    },
    {
      title: 'WHS',
      item: (
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => (
            <Form.Item className="mb0" name="warehouse">
              <WarehouseSelect
                size="small"
                multiple={true}
                mode="multiple"
                style={{ width: '100%' }}
                maxTagCount="responsive"
                selected={getFieldValue('warehouse')}
                placeholder="Select WHS"
                onSelect={(warehouse: any) => {
                  filter.setFieldsValue({ warehouse });
                  if (warehouse) {
                    const warehouseIds = warehouse.map((w: any) => w.id);
                    handleMultipleSelectChange(
                      warehouseIds,
                      warehouse.map((w: any) => ({
                        value: w.id,
                        label: w.name,
                      })),
                      dataKeys.WHS,
                      'WHS',
                    );
                  }
                }}
              />
            </Form.Item>
          )}
        </Form.Item>
      ),
      enableIsNot: true,
      key: dataKeys.WHS,
    },
    {
      title: 'WHS Special',
      isBinary: true,
      enableIsNot: false,
      key: dataKeys.WHS_SPECIAL,
    },
    {
      title: 'Trucker',
      item: (
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => (
            <Form.Item name="vendor" className="mb0">
              <VendorSelect
                size="small"
                multiple={true}
                mode="multiple"
                style={{ width: '100%' }}
                maxTagCount="responsive"
                selected={getFieldValue('vendor')}
                placeholder="Select Trucker"
                onSelect={(vendor) => {
                  filter.setFieldsValue({ vendor });
                  handleVendorChange(vendor);
                }}
              />
            </Form.Item>
          )}
        </Form.Item>
      ),
      enableIsNot: true,
      key: dataKeys.TRUCKER,
    },
    {
      title: 'Ocean carrier',
      item: (
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => (
            <Form.Item name={dataKeys.OCEAN_CARRIER} className="mb0">
              <OceanCarrierSelect
                size="small"
                multiple={true}
                mode="multiple"
                style={{ width: '100%' }}
                maxTagCount="responsive"
                selected={getFieldValue(dataKeys.OCEAN_CARRIER)}
                placeholder="Select Ocean carriers"
                onChange={(ocean_carrier, option) => {
                  filter.setFieldsValue({ ocean_carrier });
                  handleOceanCarriersChange(ocean_carrier, option);
                }}
              />
            </Form.Item>
          )}
        </Form.Item>
      ),
      enableIsNot: true,
      key: dataKeys.OCEAN_CARRIER,
    },
    {
      title: 'Terminal',
      item: (
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => (
            <Form.Item name={dataKeys.TERMINAL} className="mb0">
              <TerminalSelect
                size="small"
                style={{ width: '100%' }}
                selected={getFieldValue(dataKeys.TERMINAL)}
                placeholder="Select Terminal"
                onSelect={(terminal: any) => {
                  filter.setFieldsValue({ terminal });
                  handleTerminalsChange(terminal);
                }}
              />
            </Form.Item>
          )}
        </Form.Item>
      ),
      enableIsNot: true,
      key: dataKeys.TERMINAL,
    },
    {
      title: 'Warehouse City',
      item: (
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => (
            <Form.Item name={dataKeys.WAREHOUSE_CITY} className="mb0">
              <CitySelect
                size="small"
                style={{ width: '100%' }}
                selected={getFieldValue(dataKeys.WAREHOUSE_CITY)}
                placeholder="Select Warehouse City"
                onSelect={(city) => {
                  filter.setFieldsValue({ city });
                  handleCityChange(city);
                }}
              />
            </Form.Item>
          )}
        </Form.Item>
      ),
      enableIsNot: true,
      key: dataKeys.WAREHOUSE_CITY,
    },
    {
      title: 'Container Type',
      item: (
        <Form.Item noStyle shouldUpdate>
          {() => (
            <Form.Item name="type" className="mb0">
              <Select
                mode="multiple"
                size="small"
                onChange={(ap_status: any, options: any) => {
                  filter.setFieldsValue({ type: options });
                  handleContainerTypeChange(options);
                }}
              >
                {[
                  {
                    text: '20ft',
                    value: '20',
                  },
                  {
                    text: '40ft',
                    value: '40',
                  },
                  {
                    text: '45ft',
                    value: '45',
                  },
                ].map((v) => (
                  <Select.Option key={v.text} value={v.value}>
                    {v.text}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </Form.Item>
      ),
      enableIsNot: true,
      key: 'type',
    },
    {
      title: 'Container Task Done',
      item: (
        <Form.Item noStyle shouldUpdate>
          {() => (
            <Form.Item name={dataKeys.CONTAINER_TASK_DONE} className="mb0">
              <Select
                mode="multiple"
                size="small"
                style={{ width: 400 }}
                maxTagCount="responsive"
                filterOption={(input, option) =>
                  ((option!.children as unknown) as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={(value: any, options: any) => {
                  filter.setFieldsValue({
                    [dataKeys.CONTAINER_TASK_DONE]: options,
                  });
                  handleContainerTasksChange(options);
                }}
              >
                {settingContainerTasks?.map((v) => (
                  <Select.Option key={v.id} value={v.id}>
                    {v.task}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </Form.Item>
      ),
      enableIsNot: true,
      key: dataKeys.CONTAINER_TASK_DONE,
    },
    {
      title: 'AP Status',
      item: (
        <Form.Item noStyle shouldUpdate>
          {() => (
            <Form.Item name={dataKeys.AP_STATUS} className="mb0">
              <Select
                mode="multiple"
                size="small"
                options={DISPATCH_AP_STATUS_OPTIONS}
                onChange={(ap_status: any, options: any) => {
                  filter.setFieldsValue({ [dataKeys.AP_STATUS]: options });
                  handleStatusChange(options, dataKeys.AP_STATUS);
                }}
              ></Select>
            </Form.Item>
          )}
        </Form.Item>
      ),
      enableIsNot: true,
      key: dataKeys.AP_STATUS,
    },
    {
      title: 'AR Status',
      item: (
        <Form.Item noStyle shouldUpdate>
          {() => (
            <Form.Item name={dataKeys.AR_STATUS} className="mb0">
              <Select
                mode="multiple"
                size="small"
                options={DISPATCH_AR_STATUS_OPTIONS}
                onChange={(ar_status: any, options: any) => {
                  filter.setFieldsValue({ [dataKeys.AR_STATUS]: options });
                  handleStatusChange(options, dataKeys.AR_STATUS);
                }}
              ></Select>
            </Form.Item>
          )}
        </Form.Item>
      ),
      enableIsNot: true,
      key: dataKeys.AR_STATUS,
    },
    {
      title: 'Bill Unapproved',
      isBinary: true,
      onlyRequiredYes: true,
      enableIsNot: false,
      key: 'bill_unapproved',
    },
    {
      title: 'Statement#',
      item: (
        <Form.Item noStyle shouldUpdate>
          {() => (
            <Form.Item name="localstatement_id" className="mb0">
              <Input
                allowClear
                size="small"
                placeholder="Enter project code#"
                onKeyDown={(e) =>
                  handleInputEnter(e, 'localstatement_id', 'Statement#')
                }
                onChange={(e) =>
                  handleInputChange(
                    e.target.value,
                    'localstatement_id',
                    'Statement#',
                  )
                }
              />
            </Form.Item>
          )}
        </Form.Item>
      ),
      enableIsNot: true,
      key: 'localstatement_id',
    },
    {
      title: 'Project Code',
      item: (
        <Form.Item className="mb0" name={dataKeys.PROJECT_CODE}>
          <Input
            allowClear
            size="small"
            placeholder="Enter project code#"
            onKeyDown={(e) =>
              handleInputEnter(e, dataKeys.PROJECT_CODE, 'Project Code')
            }
            onChange={(e) =>
              handleInputChange(
                e.target.value,
                dataKeys.PROJECT_CODE,
                'Project Code',
              )
            }
          />
        </Form.Item>
      ),
      enableIsNot: true,
      key: dataKeys.PROJECT_CODE,
    },
    {
      title: 'Tags',
      item: (
        <Form.Item noStyle shouldUpdate>
          {() => (
            <Form.Item name="tags" className="mb0">
              <Select
                mode="multiple"
                size="small"
                onChange={(_: any, options: any) => {
                  filter.setFieldsValue({ tags: options });
                  handleTagsChange(options);
                }}
              >
                {(app.store.cache.get(CACHE_CONTAINER_COLOR_TAG_KEY) || []).map(
                  (v) => (
                    <Select.Option key={v.id} value={v.label}>
                      {v.label}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Form.Item>
          )}
        </Form.Item>
      ),
      enableIsNot: true,
      key: 'tags',
    },
    {
      title: 'Pierpass',
      isBinary: true,
      enableIsNot: false,
      key: 'pierpass',
    },
    {
      title: 'Task Overdue',
      isBinary: true,
      enableIsNot: false,
      key: 'task_overdue',
    },
    {
      title: 'OP Task Overdue',
      isBinary: true,
      enableIsNot: false,
      key: 'op_task_overdue',
    },
    {
      title: 'CPS Task Overdue',
      isBinary: true,
      enableIsNot: false,
      key: 'cps_task_overdue',
    },
    {
      title: 'OA Task Overdue',
      isBinary: true,
      enableIsNot: false,
      key: 'oa_task_overdue',
    },
    // {
    //   title: 'Dispatch Overdue',
    //   isBinary: true,
    //   enableIsNot: false,
    //   key: 'dispatch_overdue',
    // },
    {
      title: 'Act Overdue',
      isBinary: true,
      enableIsNot: false,
      key: 'act_overdue',
    },
    {
      title: 'Destination Type',
      item: (
        <Form.Item className="mb0" name="destination_type">
          <Select
            size="small"
            onChange={(value: string, option: any) => {
              handleMultipleSelectChange(
                [value],
                [{ value: value, label: option.key }],
                'destination_type',
                '',
              );
            }}
            allowClear
          >
            {[
              {
                text: 'Ocean',
                value: OCEAN_PORT,
              },
              {
                text: 'Ramp',
                value: RAMP_PORT,
              },
            ].map((v) => (
              <Select.Option key={v.text} value={v.value}>
                {v.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ),
      enableIsNot: false,
      key: 'destination_type',
    },
    {
      title: 'Order Date',
      item: (
        <Form.Item className="mb0" name="confirmed_at">
          <DatePicker.RangePicker
            size="small"
            onChange={(value) =>
              value &&
              handleOrderDateChange({
                start: value[0]?.format('YYYY-MM-DD') || '',
                end: value[1]?.format('YYYY-MM-DD') || '',
              })
            }
          />
        </Form.Item>
      ),
      enableIsNot: false,
      key: 'confirmed_at',
    },
    {
      title: 'F.ETA',
      item: (
        <Form.Item className="mb0" name="eta">
          <DatePicker.RangePicker
            size="small"
            onChange={(value) =>
              value &&
              handleEtaChange({
                etaStart: value[0]?.format('YYYY-MM-DD') || '',
                etaEnd: value[1]?.format('YYYY-MM-DD') || '',
              })
            }
          />
        </Form.Item>
      ),
      enableIsNot: false,
      key: 'eta',
    },
    {
      title: 'OP Assign',
      item: (
        <Form.Item className="mb0" name="no_op">
          <Select
            size="small"
            allowClear
            onChange={(v: any) =>
              handleChange(
                {
                  target: {
                    value: v,
                    attribute: 'no_op',
                  },
                },
                {
                  preferText: 'NO OP Assign',
                },
              )
            }
          >
            {[
              {
                text: 'No OP Assign',
                value: true,
              },
            ].map((v) => (
              <Select.Option key={v.text} value={v.value}>
                {v.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ),
      enableIsNot: false,
      key: 'no_op',
    },
    {
      title: 'Live Or Drop',
      item: (
        <Form.Item className="mb0" name="live_or_drop">
          <Select
            style={{
              width: '100%',
            }}
            size="small"
            value={undefined}
            onChange={(v) => handleLiveOrDropChange(v)}
          >
            {Object.keys(LIVE_OR_DROP_MAP).map((key: string) => {
              return (
                <Select.Option key={key} value={Number(key)}>
                  {
                    LIVE_OR_DROP_MAP[
                      (key as unknown) as keyof typeof LIVE_OR_DROP_MAP
                    ]
                  }
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      ),
      enableIsNot: false,
      key: 'live_or_drop',
    },
    {
      title: 'Urgent',
      isBinary: true,
      enableIsNot: false,
      key: 'urgent',
    },
    {
      title: 'DG',
      isBinary: true,
      enableIsNot: false,
      key: 'dg',
    },
    {
      title: 'SOC',
      isBinary: true,
      enableIsNot: false,
      key: 'soc',
    },
    {
      title: 'Overweight',
      isBinary: true,
      enableIsNot: false,
      key: 'overweight',
    },
    {
      title: 'REEFER',
      isBinary: true,
      enableIsNot: false,
      key: 'reefer',
    },
    {
      title: 'Bonded',
      isBinary: true,
      enableIsNot: false,
      key: 'bonded',
    },
    {
      title: 'Diversion',
      isBinary: true,
      enableIsNot: false,
      key: 'diversion',
    },
    {
      title: 'Multiple Deliveries',
      isBinary: true,
      enableIsNot: false,
      key: 'multi_deliveries',
    },
    {
      title: 'Transload',
      isBinary: true,
      enableIsNot: false,
      key: 'transload',
    },
    {
      title: 'Weekend or Holiday',
      isBinary: true,
      enableIsNot: false,
      key: 'weekend_or_holiday',
    },
    {
      title: 'X-ray',
      isBinary: true,
      enableIsNot: false,
      key: 'x_ray',
    },
    {
      title: 'MET',
      isBinary: true,
      enableIsNot: false,
      key: 'met',
    },
    {
      title: 'CET',
      isBinary: true,
      enableIsNot: false,
      key: 'cet',
    },
    {
      title: 'SR Exist',
      isBinary: true,
      enableIsNot: false,
      key: dataKeys.SR_EXIST,
    },
    {
      title: 'BR Exist',
      isBinary: true,
      enableIsNot: false,
      key: dataKeys.BR_EXIST,
    },
    {
      title: 'Storage',
      isBinary: true,
      enableIsNot: false,
      key: 'Storage',
    },
    {
      title: 'Is Verified',
      isBinary: true,
      enableIsNot: false,
      key: 'is_verified',
    },
    {
      title: 'Vendor Verified',
      isBinary: true,
      enableIsNot: false,
      key: 'vendor_verified',
    },
    {
      title: 'OOG',
      isBinary: true,
      enableIsNot: false,
      key: 'vendor_is_oog',
    },
  ];

  const rows = useMemo(() => {
    if (allowAttributes.length == 0) {
      return defaultRows.filter(
        (item) => !OPTION_ATTRIBUTES.includes(item.key),
      );
    }

    return defaultRows.filter((item) => allowAttributes.includes(item.key));
  }, [defaultRows, allowAttributes]);

  useEffect(() => {
    if (!show) {
      return;
    }

    if (
      (!checkedFilterItems || checkedFilterItems.length == 0) &&
      localActiveFilters.length > 0
    ) {
      setCheckedFilterItem(localActiveFilters.map((f) => f.attribute));
    }
  }, [checkedFilterItems, localActiveFilters]);

  useEffect(() => {
    setNewValues([]);
    setCheckedFilterItem([]);
  }, [show]);

  const handleChangeFilterItems = (checked: string[]) => {
    setCheckedFilterItem(
      Object.assign(
        uniq([
          ...checked,
          ...localActiveFilters.map((f) => f.attribute),
          ...newValues.map((n) => n.attribute),
        ]),
      ),
    );
  };

  const RESET_FIELDS = [
    ...rows.map((item) => item.key),
    ...['warehouse', 'vendor'],
  ];

  const handleChange = async (event: any, options: any = {}) => {
    const { value, attribute } = event.target;
    const { preferText, textPrefix } = options;

    const index = newValues.findIndex((v) => v.attribute === attribute);

    const text = preferText
      ? preferText
      : textPrefix
      ? `${textPrefix}: ${value}`
      : `${value}`;

    const _value = toFilterValue(text, attribute, value);
    if (index !== -1) {
      value && setNewValues(update(newValues, { [index]: { $set: _value } }));
      !value && setNewValues(update(newValues, { $splice: [[index, 1]] }));
    } else {
      setNewValues([...newValues, _value]);
    }
  };

  const handleApply = async () => {
    const filterNewValues = newValues.filter(
      (n) =>
        !localActiveFilters.find(
          (a) => a.attribute == n.attribute && a.value == n.value,
        ),
    );

    const filterActives = localActiveFilters.filter((a) => {
      if (
        SINGLE_UPDATE_ATTRIBUTE.includes(a.attribute) &&
        filterNewValues.find((n) => a.attribute == n.attribute)
      ) {
        return false;
      }

      return a;
    });
    const _meta = localActiveFilters.findIndex((n) => n.meta);
    const meta: any = {};
    const _filter = filter.getFieldsValue();
    for (const key in _filter) {
      if (key.startsWith('is_') && !RESET_FIELDS.includes(key)) {
        meta[key] = _filter[key];
      }
    }

    if (_meta > -1) {
      filterActives[_meta] = {
        meta,
      };
    } else {
      filterNewValues.push({
        meta,
      });
    }

    onChange([...filterActives, ...filterNewValues]);
    filter.resetFields(RESET_FIELDS);
    setNewValues([]);
  };

  const handleReset = () => {
    filter.resetFields(RESET_FIELDS);
    setNewValues([]);
  };

  const FieldTag = ({ field }: { field: string }) =>
    useMemo(() => {
      return (
        <div style={{ width: 'flex' }}>
          {localActiveFilters
            // .filter((item) => item.attribute == field)
            .map((item: any, index) => {
              return item.attribute == field ? (
                <Tag
                  className="ml-sm"
                  closable
                  onClose={() => {
                    removeLocalFilters(index);
                  }}
                  color={
                    filter.getFieldValue(`is_${item.attribute}`)
                      ? 'processing'
                      : ''
                  }
                  key={`tag-${item.text}-${index}`}
                >
                  {item.text}
                </Tag>
              ) : (
                ''
              );
            })}
        </div>
      );
    }, [localActiveFilters, filter]);

  return (
    <div className={styles.filter}>
      {show && (
        <Modal
          open={show}
          onCancel={() => hideFilter()}
          footer={false}
          width={1200}
        >
          <Form form={filter} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
            <div style={{ maxHeight: '70vh', overflowY: 'scroll' }}>
              <Row>
                <ManagerFilterItems
                  checkedItems={checkedFilterItems}
                  items={rows.map((item: any) => ({
                    key: item.key,
                    name: item.title,
                  }))}
                  onChange={handleChangeFilterItems}
                />
              </Row>
              <table>
                <tbody>
                  {rows
                    .filter((item) => checkedFilterItems.includes(item.key))
                    .map((item: any) => (
                      <tr key={item.key}>
                        <td width="200">{item.title}</td>
                        <td width="350">
                          {item.isBinary ? (
                            <Form.Item className="mb0" name={item.key}>
                              <Select
                                size="small"
                                allowClear
                                value={undefined}
                                style={{ width: '100%' }}
                                maxTagCount="responsive"
                                onChange={(v) =>
                                  handleBinaryChange(v, item.key, item.title)
                                }
                                options={
                                  item.onlyRequiredYes
                                    ? [YES_OPTION]
                                    : item.onlyRequiredNo
                                    ? [NO_OPTION]
                                    : BINARY_OPTIONS
                                }
                              />
                            </Form.Item>
                          ) : (
                            item.item
                          )}
                        </td>
                        <td width="80">
                          {item.enableIsNot ? (
                            <Form.Item
                              className="mb0"
                              initialValue={1}
                              name={`is_${item.key}`}
                            >
                              <Select size="small">
                                <Select.Option value={1}>Is</Select.Option>
                                <Select.Option value={0}>Is Not</Select.Option>
                              </Select>
                            </Form.Item>
                          ) : (
                            <></>
                          )}
                        </td>
                        <td width="800">
                          <FieldTag field={item.key} />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            <Row>
              <Col span={4}>
                <Form.Item label="&nbsp;" shouldUpdate>
                  <Space>
                    <Button
                      disabled={
                        (newValues.length > 0 ||
                        filter.isFieldsTouched([], true)
                          ? false
                          : true) || loading
                      }
                      type="primary"
                      onClick={handleApply}
                    >
                      Apply
                    </Button>
                    <Button onClick={handleReset} disabled={loading}>
                      Reset
                    </Button>
                    {activeFilters.length > 0 && (
                      <Button onClick={removeAll} disabled={loading}>
                        Clear All
                      </Button>
                    )}
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      )}
      {(show || activeFilters.length > 0) && (
        <Row>
          <Col span={1}>
            <div>Filters:</div>
          </Col>
          <Col span={19}>
            <Slider>
              {activeFilters
                // .filter((item) => !item.meta)
                ?.map((item: any, index) =>
                  !item?.meta ? (
                    <li key={`tag-${item?.text}-${index}`}>
                      <Tag
                        closable
                        onClose={() => {
                          removeOne(index);
                        }}
                        color={
                          filter.getFieldValue(`is_${item?.attribute}`)
                            ? 'processing'
                            : ''
                        }
                      >
                        {item.text}
                      </Tag>
                    </li>
                  ) : (
                    ''
                  ),
                )}
            </Slider>
          </Col>
          <Col span={4}>
            <div>
              {activeFilters.length > 0 && (
                <Button size="small" onClick={removeAll}>
                  Clear All
                </Button>
              )}
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};
