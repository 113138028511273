import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Form,
  List,
  Modal,
  Tooltip,
  Avatar,
  message,
  Space,
  Divider,
} from 'antd';
import {
  EditOutlined,
  PushpinFilled,
  AlignLeftOutlined,
} from '@ant-design/icons';
import { useApp } from '@/utils/useapp';
import { observer } from 'mobx-react';
import { MentionsInput, Mention } from 'react-mentions';
import WithMentionHighlighter from '@/components/WithMentionHighlighter';
import { CACHE_ADMIN_KEY } from '@/stores/cache';
import { sortBy, truncate } from 'lodash';
import { style } from './data';
import {
  DISPUTE_TYPE_LTL_SHIPMENTS,
  DISPUTE_TYPE_FTL_SHIPMENTS,
} from '@/components/DisputeMemo';

type ShipmentType = 'ftl' | 'ltl';

// 可编辑的评论项组件
const EditableItem = ({
  item,
  admins = [],
  pinComment,
  onEdited,
}: {
  item: any;
  admins: any[];
  pinComment: (item: any, pin: boolean) => void;
  onEdited: () => void;
}) => {
  const [edit, setEdit] = useState(false);
  const [comment, setComment] = useState<string>(item.comment);
  const [loading, setLoading] = useState(false);
  const app = useApp();

  const handleSave = async () => {
    try {
      const resp = await app.service.put(`adminComments/${item.id}`, {
        data: { comment },
      });
      setEdit(false);
      setComment(resp.data.comment);
      onEdited();
    } catch (err: any) {
      message.error(err?.data?.message || err?.data?.error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {!edit ? (
        <div className="flex justify-between">
          <WithMentionHighlighter
            content={comment}
            highlighterColor={'#000000'}
          />
          <Space split={<Divider type="vertical" />}>
            {app.store.auth?.user?.id == item.admin_id && (
              <EditOutlined
                className="text-primary"
                onClick={() => setEdit(true)}
              />
            )}
            <a onClick={() => pinComment(item, true)}>Pin</a>
          </Space>
        </div>
      ) : (
        <>
          <MentionsInput
            defaultValue={''}
            value={comment}
            onChange={(e) => setComment(e.target.value || '')}
            placeholder="Mention any admin by typing `@` followed by at least one char"
            required
            style={style}
          >
            <Mention
              displayTransform={(id, display) => `@${display}`}
              trigger="@"
              data={(admins || []).map((admin) => ({
                id: admin.id,
                display: admin.name,
              }))}
              style={{ backgroundColor: '#1dcae02e', color: '#024d65' }}
            />
          </MentionsInput>
          <br />
          <Space>
            <Button
              disabled={loading}
              type="primary"
              onClick={() => handleSave()}
            >
              Save
            </Button>
            <Button disabled={loading} onClick={() => setEdit(false)}>
              Cancel
            </Button>
          </Space>
        </>
      )}
    </>
  );
};

export const ShipmentDisputeMemo: React.FC<{
  onSaved?: (data: any) => void;
  initialValue?: string;
  model: any;
  open?: boolean;
  type: ShipmentType;
}> = observer(
  ({ onSaved, initialValue, model, open = false, type = 'ltl' }) => {
    const [isModalVisible, setIsModalVisible] = useState(open);
    const [comments, setComments] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [comment, setComment] = useState<string>('');
    const app = useApp();

    // 根据类型获取对应的 API 路径
    const getApiPath = useMemo(() => {
      return type === 'ftl'
        ? DISPUTE_TYPE_FTL_SHIPMENTS
        : DISPUTE_TYPE_LTL_SHIPMENTS;
    }, [type]);

    useEffect(() => {
      if (open) {
        setIsModalVisible(true);
      }
    }, [open]);

    const handleSave = async () => {
      setLoading(true);
      try {
        const resp = await app.service.post(
          `${getApiPath}/${model.id}/newMemo`,
          {
            data: { comment },
          },
        );
        onSaved && onSaved(model.id);
        setComment('');
        const data = resp.data;
        setComments([{ ...data, pin: 0 }, ...comments]);
      } catch (err: any) {
        message.error(err?.data?.message || err?.data?.error);
      } finally {
        setLoading(false);
      }
    };

    const fetchComments = async () => {
      setLoading(true);
      try {
        const resp = await app.service.get(`${getApiPath}/${model.id}/memos`);
        setComments(resp.data);
      } catch (err: any) {
        message.error(err?.data?.message || err?.data?.error);
      } finally {
        setLoading(false);
      }
    };

    const pinComment = async (item: any, pin: boolean) => {
      setLoading(true);
      try {
        await app.service.put(`adminComments/${item.id}`, {
          data: {
            pin,
          },
        });
        fetchComments().then((r) => r);
      } catch (err: any) {
        message.error(err?.data?.message || err?.data?.error);
      } finally {
        setLoading(false);
      }
    };

    const _comments = useMemo(() => {
      return sortBy(comments, ['pin', 'pin_at']).reverse();
    }, [comments]);

    useEffect(() => {
      if (isModalVisible && model) {
        fetchComments().then((r) => r);
      }
    }, [isModalVisible]);

    const rows = app.store.cache.data[CACHE_ADMIN_KEY];

    if (rows === undefined) {
      app.store.cache.fetch(CACHE_ADMIN_KEY);
    }

    return (
      <>
        <div
          className={initialValue ? 'text-primary' : 'text-gray'}
          onClick={() => setIsModalVisible(true)}
        >
          <Tooltip
            placement="left"
            title={
              initialValue && (
                <WithMentionHighlighter
                  content={truncate(initialValue, { length: 100 })}
                  highlighterColor={'#ffffff'}
                />
              )
            }
          >
            <AlignLeftOutlined />
          </Tooltip>
        </div>
        {isModalVisible && (
          <Modal
            cancelText="Close"
            footer={false}
            title={`${type.toUpperCase()} Memo`}
            width={1000}
            open={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
          >
            <Form layout="vertical" onFinish={handleSave}>
              <Form.Item label="">
                <MentionsInput
                  defaultValue={''}
                  value={comment}
                  onChange={(e) => setComment(e.target.value || '')}
                  placeholder="Mention any admin by typing `@` followed by at least one char"
                  required
                  style={style}
                >
                  <Mention
                    displayTransform={(id, display) => `@${display}`}
                    trigger="@"
                    data={(rows || []).map((admin) => ({
                      id: admin.id,
                      display: admin.name,
                    }))}
                    style={{ backgroundColor: '#1dcae02e', color: '#024d65' }}
                  />
                </MentionsInput>
              </Form.Item>
              <div className="flex justify-between">
                <Button disabled={loading} type="primary" htmlType="submit">
                  Save
                </Button>
              </div>
            </Form>
            <List
              loading={loading}
              className="demo-loadmore-list"
              itemLayout="horizontal"
            >
              {_comments.map((item) => (
                <List.Item key={item.id}>
                  <List.Item.Meta
                    avatar={
                      item.admin?.avatar_url ? (
                        <Avatar src={item.admin.avatar_url} />
                      ) : (
                        <Avatar>
                          {item.admin?.display_name?.charAt(0) || 'S'}
                        </Avatar>
                      )
                    }
                    title={
                      <>
                        <span>{item.admin?.display_name || 'System'}</span> @
                        <span className="text-gray">{item.created_at} </span>
                        {!!item.pin && (
                          <PushpinFilled
                            style={{ color: item.pin ? 'orange' : 'black' }}
                            onClick={() => pinComment(item, false)}
                          />
                        )}
                      </>
                    }
                    description={
                      <>
                        <EditableItem
                          item={item}
                          admins={rows}
                          pinComment={pinComment}
                          onEdited={fetchComments}
                        />
                        {item.change_logs?.length > 0 && (
                          <div className="mt-2">
                            {[...item.change_logs]
                              .sort((a, b) => b.id - a.id)
                              .map((log: any, index: number) => (
                                <div
                                  key={index}
                                  style={{ textDecoration: 'line-through' }}
                                >
                                  <WithMentionHighlighter
                                    content={log.comment}
                                    highlighterColor={'#808080'}
                                  />
                                </div>
                              ))}
                          </div>
                        )}
                      </>
                    }
                  />
                </List.Item>
              ))}
            </List>
          </Modal>
        )}
      </>
    );
  },
);
