import { Button, Checkbox, Form, Input, message } from 'antd';
import styles from 'res/css/login.scss';
import { NavLink, useHistory, useLocation } from 'umi';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useApp } from '@/utils/useapp';
import { useCallback, useState } from 'react';
import React from 'react';
import qs from 'qs';
// import TurnstileComponent from '@/components/Turnstile';

const initialValues: { [key: string]: any } = {};
const REMEMBER_KEY = 'de_remember';

const rememberAccountAndUsername = (account?: string, username?: string) => {
  if (typeof account === 'undefined' && typeof username === 'undefined') {
    localStorage.removeItem(REMEMBER_KEY);
  } else {
    localStorage.setItem(REMEMBER_KEY, JSON.stringify({ account, username }));
  }
};

const rememberData = localStorage.getItem(REMEMBER_KEY);
if (rememberData) {
  try {
    const json = JSON.parse(rememberData);
    initialValues.remember = true;
    initialValues.account = json.account;
    initialValues.username = json.username;
  } catch (e) {
    initialValues.remember = false;
  }
}

const UserLogin = () => {
  const app = useApp();
  const [required2fa, setRequired2fa] = React.useState(false);
  // const [turnstileToken, setTurnstileToken] = useState<string>('');
  const history = useHistory();
  const { search } = useLocation();

  const urlParams = qs.parse(search, { ignoreQueryPrefix: true });

  const onSubmit = useCallback(
    async (values: any) => {
      // if (TURNSTILE_VERIFY === 'true' && !turnstileToken) {
      //   message.error('Please complete the human verification');
      //   return;
      // }

      const previousPathName = history.location.state?.from || '/';

      try {
        const user = await app.service.post('login', {
          data: {
            username: values.username,
            password: values.password,
            code_2fa: values.code_2fa,
            // ...(TURNSTILE_VERIFY === 'true'
            //   ? { turnstile_token: turnstileToken }
            //   : {}),
            ...urlParams,
          },
        });

        app.store.auth.login(user);

        if (values.remember) {
          rememberAccountAndUsername(values.account, values.username);
        } else {
          rememberAccountAndUsername();
        }

        if (urlParams.sso && urlParams.redirect) {
          window.location.href =
            urlParams.redirect + '?token=' + user.sso_token;
          return;
        }

        // history.goBack();
        history.push(previousPathName);
      } catch (e: any) {
        if (e.data && e.data.code === 'code_2fa_required') {
          setRequired2fa(true);
        } else {
          setRequired2fa(false);
          message.error(e.data.message || e.data.error);
        }
      }
    },
    [urlParams],
    // [urlParams, turnstileToken],
  );

  return (
    <div className={styles.login}>
      <div className={styles.modal}>
        <div className={styles.content}>
          <div className={styles.logo}>
            <h1>DrayEasy Admin</h1>
          </div>
          <Form
            name="login"
            layout="vertical"
            initialValues={initialValues}
            autoComplete="off"
            onFinish={onSubmit}
          >
            <Form.Item
              name="username"
              rules={[{ required: true, message: 'Please Enter Username' }]}
            >
              <Input
                size="large"
                placeholder="Username"
                prefix={<UserOutlined />}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Please Input Password' }]}
            >
              <Input.Password
                size="large"
                placeholder="Password"
                prefix={<LockOutlined />}
              />
            </Form.Item>
            {required2fa && (
              <Form.Item
                name="code_2fa"
                rules={[{ required: true, message: 'Please 2FA Code' }]}
              >
                <Input
                  size="large"
                  placeholder="2FA Code"
                  prefix={<LockOutlined />}
                />
              </Form.Item>
            )}

            {/*{TURNSTILE_VERIFY === 'true' && (*/}
            {/*  <Form.Item>*/}
            {/*    <TurnstileComponent*/}
            {/*      onVerify={setTurnstileToken}*/}
            {/*      onError={(err: any) => {*/}
            {/*        console.log(err);*/}
            {/*        message.error(*/}
            {/*          'Human verification failed, please try again',*/}
            {/*        );*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  </Form.Item>*/}
            {/*)}*/}

            {/*<Form.Item className="pull-right">*/}
            {/*  <NavLink to="/user/reset">Reset Password</NavLink>*/}
            {/*</Form.Item>*/}
            {/*<Form.Item name="remember" valuePropName="checked">*/}
            {/*  <Checkbox>Remember me</Checkbox>*/}
            {/*</Form.Item>*/}
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                Login
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default UserLogin;
