import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import { Button, Divider, message, Popconfirm, Table, Tag } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { Header } from '@/components/CommonHeader';
import { Filter } from './components/Filter';
import type { ColumnsType } from 'antd/lib/table';
import { get } from 'lodash';
import { WarehouseDrawer } from './components/WarehouseDrawer';
import { useForm } from 'antd/lib/form/Form';
import AutoResizeTable from '@/components/AutoResizeTable';
import { observer } from 'mobx-react';

const CNTRCount = observer(({ warehouseId }: { warehouseId: number }) => {
  const app = useApp();
  const count = useMemo(() => {
    return (
      app.store.bufferCache.getCntrCount(warehouseId)?.container_count || 0
    );
  }, [app.store.bufferCache.data]);

  return (
    <a
      type="link"
      target="_blank"
      rel="noreferrer"
      className="text-gray"
      href={`${window.location.origin}/dispatchs?link_warehouse_id=${warehouseId}`}
    >
      <Tag color="cyan">{count}</Tag>
    </a>
  );
});

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [filter, setFilter] = useState<any>({});
  const [data, setData] = useState<any[]>([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 12,
    total: 0,
  });
  const [warehouse, setWarehouse] = useState<any>(null);
  const [form] = useForm();
  const showDrawer = () => {
    setVisible(true);
  };

  const handelCloseDrawer = () => {
    setVisible(false);
    setWarehouse(null);
  };

  const addNew = () => {
    showDrawer();
  };

  const handleFilterChange = (values: any) => {
    console.log(values);
    setFilter(values);
  };

  const fetchData = async ({ page } = { page: 1 }) => {
    const _filter = Object.assign({}, filter);
    if (_filter.city) {
      _filter.city_id = _filter.city.id;
    }
    delete _filter.city;
    setLoading(true);
    try {
      const result = await app.service.get('warehouses', {
        params: {
          ..._filter,
          per_page: pagination.pageSize,
          page: page || pagination.current,
        },
      });
      setPagination({
        current: result.meta.current_page,
        pageSize: result.meta.per_page,
        total: result.meta.total,
      });
      setData(result.data);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const handleRemove = (id: number) => {
    setLoading(true);
    app.service
      .delete(`warehouses/${id}`)
      .then((resp) => {
        fetchData({ page: pagination.current });
      })
      .catch((err) => {
        message.error(err.data?.message || err.data?.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEdit = (id: number) => {
    setLoading(true);
    app.service
      .get(`warehouses/${id}`)
      .then((resp) => {
        setVisible(true);
        setWarehouse(id);
        form.setFieldsValue({ ...resp.data });
      })
      .catch((err) => {
        message.error(err.data?.message || err.data?.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSearch = () => {
    fetchData();
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleSave = () => {
    fetchData({ page: pagination.current });
    setWarehouse(null);
  };

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 80,
      render: (text, record, index) => <>{record.id}</>,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 200,
      render: (text, record, index) => {
        app.store.bufferCache.debounceFetch('containerCounts', record.id);
        return (
          <>
            {record.is_special && <Tag>S</Tag>}
            {record.name} <CNTRCount warehouseId={record.id} />
          </>
        );
      },
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      width: 200,
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      width: 200,
      render: (text, record) => {
        return <>{get(record, 'city.full_name', '')}</>;
      },
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      width: 200,
    },
    {
      title: 'D/O Address',
      dataIndex: 'delivery_order_address',
      key: 'delivery_order_address',
      width: 200,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 200,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      width: 200,
    },
    {
      title: 'Contact',
      dataIndex: 'contact',
      key: 'contact',
      width: 200,
    },
    {
      title: 'Related Customer',
      dataIndex: 'user',
      key: 'user',
      width: 200,
      render: (text, record) => {
        if (record.user) {
          return (
            <>
              {' '}
              {get(record, 'user.company.code', '')}(
              {get(record, 'user.name', '')})
            </>
          );
        }
        return <></>;
      },
    },
    {
      align: 'center',
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 150,
      render: (text, record) => (
        <span>
          <a onClick={() => handleEdit(record.id)}>Edit</a>
          <Divider type="vertical" />
          <Popconfirm
            title="Sure to delete?"
            okText="Confirm"
            cancelText="Cancel"
            onConfirm={() => handleRemove(record.id)}
          >
            <a>Delete</a>
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <div className={styles.main}>
      <Header
        title="Warehouses"
        rightElement={
          <div>
            <Button type="primary" onClick={addNew}>
              New Warehouse
            </Button>
          </div>
        }
      ></Header>

      <Filter
        loading={loading}
        filter={filter}
        onSearch={handleSearch}
        onChange={handleFilterChange}
      >
        {' '}
      </Filter>
      <div>
        <AutoResizeTable
          pagination={pagination}
          loading={loading}
          size="small"
          rowKey="id"
          columns={columns}
          onChange={(pagination) => {
            fetchData({ page: pagination.current || 1 });
          }}
          dataSource={[...data]}
          scroll={{ x: 1500 }}
          sticky
        />
      </div>

      <WarehouseDrawer
        id={warehouse}
        onSaved={handleSave}
        onClosed={handelCloseDrawer}
        visible={visible}
      />
    </div>
  );
};

export default Index;
