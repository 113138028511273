const CUSTOMER = 'user_id';
const CUSTOMER_COMPANY = 'company_id';
const OPERATOR = 'operator_id';
const OP_ASSISTANT = 'operator_assistant_id';
const BUSINESS_DEVELOPMENT = 'business_development_id';
const SALES = 'sales_id';
const SALES_SUPPORT = 'sales_support_id';
const POD = 'port_of_discharge_id';
const IR = 'final_port_id';
const WHS = 'warehouse_id';
const WHS_SPECIAL = 'warehouse_special';
const TRUCKER = 'vendor_id';
const OCEAN_CARRIER = 'ocean_carrier_id';
const TERMINAL = 'terminal_id';
const WAREHOUSE_CITY = 'city_id';
const AP_STATUS = 'ap_status';
const AR_STATUS = 'ar_status';
const PROJECT_CODE = 'project_code';
const SR_EXIST = 'SRExist';
const BR_EXIST = 'BRExist';
const CONTAINER_TASK_DONE = 'setting_container_task_ids';
const TITLE = 'title';
const AGENT = 'agent_id';
const CARRIER_PARTNER_SUCCESS = 'carrier_partner_success_id';
export default {
  CUSTOMER,
  CUSTOMER_COMPANY,
  OPERATOR,
  OP_ASSISTANT,
  BUSINESS_DEVELOPMENT,
  SALES,
  SALES_SUPPORT,
  POD,
  IR,
  WHS,
  WHS_SPECIAL,
  TRUCKER,
  OCEAN_CARRIER,
  TERMINAL,
  WAREHOUSE_CITY,
  AP_STATUS,
  AR_STATUS,
  PROJECT_CODE,
  SR_EXIST,
  BR_EXIST,
  CONTAINER_TASK_DONE,
  TITLE,
  AGENT,
  CARRIER_PARTNER_SUCCESS,
} as const;
